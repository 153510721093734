/*
 * @Author: chenxing
 * @Date: 2021-03-09 12:04:58
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-02-09 15:43:43
 */
/**
 * meta : {
    roles: ['ADMINMANAGER','editor']    路由对应的角色
    title: 'title'               菜单名称
    icon: 'svg-name'/'el-icon-x' 菜单图标
    onlyOneChild: true           该菜单是否只有一个子级，如为true，则渲染为根级菜单项
  }
 */
let Layout = () => import('@/components/Layout');
let noSideLayout = () => import('@/components/Layout/noSideLayout.vue');
let emptyComponent = () => import('@/components/Layout/emptyComponent.vue');
const routes = [
  {
    path: '/',
    component: Layout,
    key: 'homepage',
    redirect: '/dashboard',
    meta: {
      onlyOneChild: true,
      icon: 'homepage',
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/dashboard'),
      },
      {
        path: '/dashboard/detail',
        name: 'dashboardDetail',
        hidden: true,
        meta: { title: '首页详情' },
        component: () => import('@/views/dashboard/homeDetail'),
      },
    ],
  },
  {
    path: '/customer',
    component: Layout,
    key: 'customer',
    redirect: '/customer/index',
    meta: {
      title: '客户管理',
      icon: 'customer',
    },
    children: [
      {
        path: '/customer/index',
        name: 'customer',
        meta: {
          title: '客户管理',
          upMenuKey: 'customer',
        },
        component: () => import('@/views/customer'),
      },
      {
        path: '/customer/product',
        name: 'product',
        meta: {
          title: '产品管理',
          upMenuKey: 'customer',
        },
        component: () => import('@/views/customer/product/product.vue'),
      },
      {
        path: '/customer/media',
        name: 'mediaAccount',
        meta: {
          title: '媒体账号管理',
          upMenuKey: 'customer',
        },
        component: () => import('@/views/mediaAccount'),
      },
      {
        path: '/customer/mediaRebate',
        name: 'mediaRebate',
        meta: {
          title: '媒体返点',
          upMenuKey: 'customer',
        },
        component: () => import('@/views/customer/mediaRebate'),
      },
      // {
      //     path: '/customer/secondaryAgent',
      //     name: 'secondaryAgent',
      //     meta: {
      //         title: '二级代理管理',
      //         upMenuKey: 'customer'
      //     },
      //     component: () => import('@/views/customer/secondaryAgent')
      // },
    ],
  },
  {
    path: '/popularize',
    component: Layout,
    key: 'popularize',
    redirect: '/popularize/TXGG',
    meta: { title: '推广管理', icon: 'popularize' },
    children: [
      {
        path: '/popularize/JLYQ2',
        name: 'JLYQ2',
        meta: { title: '巨量引擎2.0', upMenuKey: 'popularize' },
        component: () => import('@/views/popularize/jlyq2'),
      },
      {
        path: '/popularize/TXGG',
        name: 'TXGG',
        meta: { title: '腾讯广告', upMenuKey: 'popularize' },
        component: () => import('@/views/popularize/txgg'),
      },
      {
        path: '/popularize/JLYQ',
        name: 'JLYQ',
        meta: { title: '巨量引擎', upMenuKey: 'popularize' },
        component: () => import('@/views/popularize/jlyq'),
      },
      {
        path: '/popularize/CLYQ',
        name: 'CLYQ',
        meta: { title: '磁力引擎', upMenuKey: 'popularize' },
        component: () => import('@/views/popularize/clyq'),
      },
    ],
  },
  {
    path: '/company',
    component: Layout,
    key: 'company',
    redirect: '/company/org',
    meta: {
      title: '企业管理',
      icon: 'company',
    },
    children: [
      {
        path: '/company/org',
        name: 'org',
        meta: {
          title: '用户管理',
          upMenuKey: 'company',
        },
        component: () => import('@/views/org'),
      },
      {
        path: '/company/workTime',
        name: 'workTime',
        meta: {
          title: '工时管理',
          upMenuKey: 'company',
        },
        component: () => import('@/views/worktime'),
      },
    ],
  },
  {
    path: '/report',
    component: Layout,
    key: 'report',
    redirect: '/report/index',
    meta: {
      title: '数据中心',
      onlyOneChild: false,
      icon: 'report',
    },
    children: [
      {
        path: '/report/index',
        name: 'report',
        meta: {
          title: '数据中心',
        },
        component: () => import('@/views/report'),
      },
      // {
      //   path: '/report/material/index',
      //   name: 'reportMaterial',
      //   meta: {
      //     title: '素材中心',
      //   },
      //   component: () => import('@/views/reportMaterial'),
      // },
    ],
  },
  {
    path: '/asset',
    component: Layout,
    key: 'asset',
    redirect: '/asset/material',
    meta: {
      title: '资产管理',
      icon: 'assets',
    },
    children: [
      {
        path: '/asset/material',
        key: 'material',
        component: emptyComponent,
        redirect: '/asset/material/video',
        meta: {
          title: '素材库',
          icon: 'material',
          upMenuKey: 'asset',
        },
        children: [
          {
            path: '/asset/material/video',
            name: 'materialVideo',
            meta: {
              title: '视频库',
              upMenuKey: 'material',
            },
            component: () => import('@/views/material/video.vue'),
          },
          {
            path: '/asset/material/image',
            name: 'materialImage',
            meta: {
              title: '图片库',
              upMenuKey: 'material',
            },
            component: () => import('@/views/material/image.vue'),
          },
          {
            path: '/asset/material/preview',
            name: 'materialPreview',
            hidden: true,
            meta: {
              title: '素材预览',
              upMenuKey: 'material',
              hideTags: true,
            },
            component: () => import('@/views/material/preview.vue'),
          },
        ],
      },
      {
        path: '/asset/videoScript',
        key: 'videoScript',
        component: emptyComponent,
        redirect: '/asset/videoScript/index',
        meta: {
          title: '短视频脚本',
          icon: 'videoscript',
          upMenuKey: 'asset',
        },
        children: [
          {
            path: '/asset/actor/todayActor',
            name: 'todayActor',
            meta: {
              title: '当日合作演员',
              upMenuKey: 'videoScript',
            },
            component: () => import('@/views/actor/todayActor'),
          },
          {
            path: '/asset/videoScript/index',
            name: 'videoScriptIndex',
            meta: {
              title: '短视频脚本',
              upMenuKey: 'videoScript',
            },
            component: () => import('@/views/videoScript'),
          },
          {
            path: '/asset/videoScript/cost',
            name: 'videoScriptlCost',
            meta: {
              title: '脚本成本',
              upMenuKey: 'videoScript',
            },
            component: () => import('@/views/videoScript/cost.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/systemManage',
    component: Layout,
    key: 'systemManage',
    redirect: '/systemManage/org',
    meta: {
      title: '系统管理',
      icon: 'system',
    },
    children: [
      {
        path: '/systemManage/role',
        name: 'role',
        meta: {
          title: '角色管理',
          upMenuKey: 'systemManage',
        },
        component: () => import('@/views/systemManage/role.vue'),
      },
      {
        path: '/systemManage/users',
        name: 'users',
        meta: {
          title: '用户权限',
          roles: ['ADMINMANAGER'],
          upMenuKey: 'systemManage',
        },
        component: () => import('@/views/systemManage/users.vue'),
      },
      {
        path: '/systemManage/pageAccess',
        name: 'pageAccess',
        meta: {
          title: '菜单管理',
          upMenuKey: 'systemManage',
        },
        component: () => import('@/views/systemManage/pageAccess.vue'),
      },
    ],
  },
  {
    path: '/updateNotification',
    component: Layout,
    key: 'updateNotification',
    redirect: '/updateNotification/index',
    meta: {
      title: '更新日志',
      icon: 'log',
      onlyOneChild: true,
    },
    children: [
      {
        path: '/updateNotification/index',
        name: '/updateNotification/index',
        meta: {
          title: '更新日志',
        },
        component: () => import('@/views/updateNotification/index.vue'),
      },
    ],
  },
  {
    path: '/MaterialAnalysis',
    component: noSideLayout,
    key: 'MaterialAnalysis',
    hidden: true,
    redirect: '/MaterialAnalysis/index',
    meta: {
      title: '数据分析',
      icon: 'log',
      onlyOneChild: true,
    },
    children: [
      {
        path: '/MaterialAnalysis/index',
        name: '/MaterialAnalysis/index',
        meta: {
          title: '数据分析',
          hideTags: true,
        },
        component: () => import('@/views/materialAnalysis/index.vue'),
      },
    ],
  },
  {
    path: '/center/task',
    component: noSideLayout,
    key: 'center',
    hidden: true,
    meta: {
      onlyOneChild: true,
    },
    children: [
      {
        path: '/center/task',
        name: 'taskCenter',
        meta: {
          title: '任务中心',
        },
        component: () => import('@/views/center/task.vue'),
      },
    ],
  },
  {
    path: '/manageTools',
    component: Layout,
    key: 'manageTools',
    redirect: '/manageTools/hosting',
    meta: {
      title: '管理工具',
      icon: 'manageTools',
    },
    children: [
      {
        path: '/manageTools/hosting',
        name: 'hosting',
        meta: {
          title: '自定义托管',
        },
        component: () => import('@/views/hosting'),
      },
      {
        path: '/manageTools/finance',
        name: 'datatool',
        meta: {
          title: '财务报表',
        },
        component: () => import('@/views/finance'),
      },
      {
        path: '/manageTools/datatool',
        name: 'hosting',
        meta: {
          title: '账号-素材核查',
        },
        component: () => import('@/views/datatool'),
      },
/*      {
        path: '/manageTools/violationInformation',
        name: 'violationInformation',
        meta: {
          title: '违规信息',
        },
        component: () => import('@/views/violationInformation'),
      },*/
    ],
  },
  {
      path: '/funds',
      component: Layout,
      key: 'funds',
      redirect: '/funds/index',
      meta: { title: '资金管理', icon: 'assets', onlyOneChild: false, },
      children: [
      {
          path: '/funds/balance',
          name: 'balance',
          meta: { title: '账户资金', },
          component: () => import('@/views/funds/balance.vue')
      },
      {
        path: '/funds/recharge',
        name: 'recharge',
        meta: { title: '充值记录', },
        component: () => import('@/views/funds/recharge.vue')
      },
      {
        path: '/funds/exchange',
        name: 'exchange',
        meta: { title: '资金置换', },
        component: () => import('@/views/funds/exchange.vue')
      },
      {
          path: '/funds/flow',
          name: 'flow',
          meta: { title: '趴款看板', },
          component: () => import('@/views/funds/flow.vue')
      }
      ]
  }

  // {
  //     path: '/actor',
  //     component: Layout,
  //     redirect: '/actor/index',
  //     key: 'actor',
  //     meta: { title: '演员', icon: 'UserOutlined' },
  //     children: [{
  //         path: '/actor/index',
  //         name: 'actorIndex',
  //         meta: { title: '演员管理', upMenuKey: 'actor', },
  //         component: () => import('@/views/actor/index')
  //     },
  //     ]
  // }
];

export let routesList = [
  {
    component: Layout,
    path: '/',
    key: 'homepage',
    redirect: '/dashboard',
    meta: { title: '首页', icon: 'homepage', onlyOneChild: true },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { title: '首页', upMenuKey: 'homepage' },
    component: () => import('@/views/dashboard'),
  },
  {
    path: '/dashboard/detail',
    name: 'dashboardDetail',
    hidden: true,
    meta: { title: '首页详情', upMenuKey: 'homepage' },
    component: () => import('@/views/dashboard/homeDetail'),
  },
  {
    component: Layout,
    path: '/customer',
    key: 'customer',
    redirect: '/customer/index',
    meta: { title: '客户管理', icon: 'customer' },
  },
  {
    path: '/customer/index',
    name: 'customer',
    meta: { title: '客户信息', upMenuKey: 'customer' },
    component: () => import('@/views/customer'),
  },
  {
    path: '/customer/product',
    name: 'product',
    meta: { title: '产品管理', upMenuKey: 'customer' },
    component: () => import('@/views/customer/product/product.vue'),
  },
  {
    path: '/customer/media',
    name: 'mediaAccount',
    meta: { title: '媒体账号管理', upMenuKey: 'customer' },
    component: () => import('@/views/mediaAccount'),
  },
  {
    path: '/customer/mediaRebate',
    name: 'mediaRebate',
    meta: { title: '媒体返点', upMenuKey: 'customer' },
    component: () => import('@/views/customer/mediaRebate'),
  },
  // {
  //   path: '/customer/tag',
  //   name: 'tag',
  //   meta: {
  //     title: '标签管理',
  //     upMenuKey: 'customer',
  //   },
  //   component: () => import('@/views/customer/tagManage/index.vue'),
  // },
  {
    component: Layout,
    path: '/popularize',
    key: 'popularize',
    redirect: '/popularize/TXGG',
    meta: { title: '推广管理', icon: 'popularize' },
  },
  {
    path: '/popularize/JLYQ2',
    name: 'JLYQ2',
    meta: { title: '巨量引擎2.0', upMenuKey: 'popularize' },
    component: () => import('@/views/popularize/jlyq2'),
  },
  {
    path: '/popularize/TXGG',
    name: 'TXGG',
    meta: { title: '腾讯广告', upMenuKey: 'popularize' },
    component: () => import('@/views/popularize/txgg'),
  },
  {
    path: '/popularize/JLYQ',
    name: 'JLYQ',
    meta: { title: '巨量引擎', upMenuKey: 'popularize' },
    component: () => import('@/views/popularize/jlyq'),
  },
  {
    path: '/popularize/CLYQ',
    name: 'CLYQ',
    meta: { title: '磁力引擎', upMenuKey: 'popularize' },
    component: () => import('@/views/popularize/clyq'),
  },
  {
    component: Layout,
    path: '/company',
    key: 'company',
    redirect: '/company/org',
    meta: { title: '企业管理', icon: 'company' },
  },
  {
    path: '/company/org',
    name: 'org',
    meta: { title: '员工信息', upMenuKey: 'company' },
    component: () => import('@/views/org'),
  },
  {
    path: '/company/workTime',
    name: 'workTime',
    meta: { title: '工时管理', upMenuKey: 'company' },
    component: () => import('@/views/worktime'),
  },
  {
    component: Layout,
    path: '/report',
    key: 'report',
    redirect: '/report/index',
    meta: { title: '数据中心', onlyOneChild: false, icon: 'report' },
  },
  {
    path: '/report/index',
    name: 'report',
    meta: {
      title: '数据中心',
    },
    component: () => import('@/views/report'),
  },
  // {
  //   path: '/report/material/index',
  //   name: 'reportMaterial',
  //   meta: {
  //     title: '素材中心',
  //   },
  //   component: () => import('@/views/reportMaterial'),
  // },
  {
    component: Layout,
    path: '/asset',
    key: 'asset',
    redirect: '/asset/material',
    meta: { title: '资产管理', icon: 'assets' },
  },
  {
    path: '/asset/material',
    key: 'material',
    redirect: '/asset/material/video',
    meta: { title: '素材库', icon: 'material', upMenuKey: 'asset' },
    component: emptyComponent,
  },
  {
    path: '/asset/material/video',
    name: 'materialVideo',
    meta: { title: '视频库', upMenuKey: 'material' },
    component: () => import('@/views/material/video.vue'),
  },
  {
    path: '/asset/material/image',
    name: 'materialImage',
    meta: { title: '图片库', upMenuKey: 'material' },
    component: () => import('@/views/material/image.vue'),
  },
  {
    path: '/asset/videoScript',
    key: 'videoScript',
    redirect: '/asset/videoScript/index',
    meta: { title: '短视频脚本', icon: 'videoscript', upMenuKey: 'asset' },
    component: emptyComponent,
  },
  {
    path: '/asset/actor/todayActor',
    name: 'todayActor',
    meta: { title: '当日合作演员', upMenuKey: 'videoScript' },
    component: () => import('@/views/actor/todayActor'),
  },
  {
    path: '/asset/videoScript/index',
    name: 'videoScriptIndex',
    meta: { title: '短视频脚本', upMenuKey: 'videoScript' },
    component: () => import('@/views/videoScript'),
  },
  {
    path: '/asset/videoScript/cost',
    name: 'videoScriptlCost',
    meta: { title: '脚本成本', upMenuKey: 'videoScript' },
    component: () => import('@/views/videoScript/cost.vue'),
  },
  {
    component: Layout,
    path: '/systemManage',
    key: 'systemManage',
    redirect: '/systemManage/org',
    meta: { title: '系统管理', icon: 'system' },
  },
  {
    path: '/systemManage/role',
    name: 'role',
    meta: { title: '角色管理', upMenuKey: 'systemManage' },
    component: () => import('@/views/systemManage/role.vue'),
  },
  {
    path: '/systemManage/users',
    name: 'users',
    meta: { title: '用户权限', roles: ['ADMINMANAGER'], upMenuKey: 'systemManage' },
    component: () => import('@/views/systemManage/users.vue'),
  },
  {
    path: '/systemManage/pageAccess',
    name: 'pageAccess',
    meta: { title: '菜单管理', upMenuKey: 'systemManage' },
    component: () => import('@/views/systemManage/pageAccess.vue'),
  },
  {
    component: Layout,
    path: '/updateNotification',
    key: 'updateNotification',
    redirect: '/updateNotification/index',
    meta: { title: '更新日志', icon: 'log', onlyOneChild: true },
  },
  {
    path: '/updateNotification/index',
    name: '/updateNotification/index',
    meta: { title: '更新日志' },
    component: () => import('@/views/updateNotification/index.vue'),
  },
  // 没有出现在左侧菜单的配置
  {
    component: noSideLayout,
    path: '/MaterialAnalysis',
    key: 'MaterialAnalysis',
    hidden: true,
    redirect: '/MaterialAnalysis/index',
    meta: { title: '数据分析', icon: 'log', onlyOneChild: true },
  },
  {
    path: '/MaterialAnalysis/index',
    name: '/MaterialAnalysis/index',
    meta: { title: '数据分析', hideTags: true },
    component: () => import('@/views/materialAnalysis/index.vue'),
  },
  {
    component: noSideLayout,
    path: '/centertask',
    key: 'center',
    hidden: true,
    meta: { title: '任务中心', onlyOneChild: true, icon: '' },
  },
  {
    path: '/center/task',
    name: 'taskCenter',
    meta: { title: '任务中心' },
    component: () => import('@/views/center/task.vue'),
  },
  {
    component: Layout,
    path: '/manageTools',
    key: 'manageTools',
    redirect: '/manageTools/hosting',
    meta: { title: '管理工具', icon: 'manageTools' },
  },
  {
    path: '/manageTools/hosting',
    name: 'hosting',
    meta: {
      title: '自定义托管',
    },
    component: () => import('@/views/hosting'),
  },
  {
    path: '/manageTools/finance',
    name: 'fiance',
    meta: {
      title: '财务报表',
    },
    component: () => import('@/views/finance'),
  },
  {
    path: '/manageTools/datatool',
    name: 'datatool',
    meta: {
      title: '账号-素材核查',
    },
    component: () => import('@/views/datatool'),
  },
  {
    path: '/manageTools/violationInformation',
    name: 'violationInformation',
    meta: {
      title: '违规信息',
    },
    component: () => import('@/views/violationInformation'),
  },
  {
    path: '/manageTools/advlog',
    name: 'advLog',
    meta: {
      title: '操作日志',
    },
    component: () => import('@/views/advLog'),
  },
  {
    path: '/manageTools/businessWaring',
    name: 'businessWaring',
    meta: {
      title: '业务预警',
    },
    component: () => import('@/views/businessWaring'),
  },
  {
    component: emptyComponent,
    path: '/other',
    key: 'empty',
    redirect: '/',
    meta: { title: '', icon: 'homepage' },
  },
  {
    path: '/other/preview',
    name: 'materialPreview',
    hidden: true,
    meta: {
      title: '素材预览',
      upMenuKey: 'material',
      hideTags: true,
    },
    component: () => import('@/views/material/preview.vue'),
  },
  {
    component: Layout,
    path: '/funds',
    key: 'funds',
    redirect: '/funds/index',
    meta: { title: '资金管理', icon: 'assets' },
  },
  {
    path: '/funds/balance',
    name: 'balance',
    meta: { title: '账户资金', },
    component: () => import('@/views/funds/balance.vue')
  },
  {
    path: '/funds/recharge',
    name: 'recharge',
    meta: { title: '充值记录', },
    component: () => import('@/views/funds/recharge.vue')
  },
  {
    path: '/funds/exchange',
    name: 'exchange',
    meta: { title: '资金置换', },
    component: () => import('@/views/funds/exchange.vue')
  },
  {
    path: '/funds/flow',
    name: 'flow',
    meta: { title: '趴款看板', },
    component: () => import('@/views/funds/flow.vue')
  },
];

if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_DEV_TEST === 'TRUE') {
  routes.push({
    path: '/oss',
    component: Layout,
    redirect: '/test/index',
    meta: { title: 'OSS文件管理', onlyOneChild: true, icon: 'videoscript' },
    children: [
      {
        path: '/test/index',
        name: 'test',
        meta: { title: 'OSS文件管理' },
        component: () => import('@/views/test'),
      },
    ],
  });

  routesList = routesList.concat([
    {
      path: '/test',
      component: Layout,
      redirect: '/test/index',
      meta: { title: '测试模块', onlyOneChild: true, icon: 'videoscript' },
    },
    {
      path: '/test/index',
      // component: Layout,
      name: 'test',
      meta: { title: '测试' },
      component: () => import('@/views/test'),
    },
  ]);
}

export default routes;
